import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { ToastContainer, toast } from "react-toastify"; // Importar ToastContainer e toast
import 'react-toastify/dist/ReactToastify.css'; // Importar estilos

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
);

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_FILES_TYPES = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const formSchema = z.object({
  name: z
    .string({
      message: "O nome é obrigatório",
    })
    .min(3, {
      message: "O campo precisa ter mais que 3 caracteres",
    })
    .transform((name) => {
      return name
        .trim()
        .split(" ")
        .map((word) => word[0].toLocaleUpperCase().concat(word.substring(1)))
        .join(" ");
    }),
  email: z
    .string({ message: "O e-mail é obrigatório" })
    .min(1, { message: "O e-mail é obrigatório" })
    .email({ message: "Formato de e-mail inválido" })
    .toLowerCase(),
  phone: z.string({ message: "O telefone é obrigatório" }).regex(phoneRegex, {
    message: "Telefone inválido",
  }),
  file: z
    .instanceof(File, { message: "O currículo é obrigatório" }) // Usando File ao invés de FileList
    .refine((file) => file.size <= MAX_FILE_SIZE, "Tamanho máximo de 5mb")
    .refine(
      (file) => ACCEPTED_FILES_TYPES.includes(file.type),
      "Formato de arquivo inválido"
    ),
  bio: z
    .string({ message: "Mensagem obrigatória" })
    .min(10, {
      message: "A mensagem precisa ter mais que 10 caracteres.",
    })
    .max(160, {
      message: "A mensagem precisa ter menos que 160 caracteres.",
    }),
});

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null); // Adiciona estado para o arquivo
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Referência para o input de arquivo
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      bio: "",
    },
  });

  const resetForm = () => {
    form.reset(); // Reseta todos os campos do formulário
    setFile(null); // Limpa o arquivo
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Limpa o valor do input de arquivo
    }
  };

  async function onSubmit(data: z.infer<typeof formSchema>) {
    setIsSubmitting(true);

    // Cria um FormData para enviar o arquivo junto com os outros dados
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    if (file) { // Verifica se o arquivo existe antes de anexar
      formData.append("file", file, file.name); // Preservar nome original do arquivo
    }
    formData.append("bio", data.bio);

    try {
      const response = await axios.post(
        "https://api.asphaltpavimentacao.com.br/wp-json/custom/v1/send-email",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Email enviado com sucesso!"); // Usar toast para notificação de sucesso
      } else {
        toast.error("Erro ao enviar o email. Tente novamente."); // Usar toast para notificação de erro
      }

    } catch (error) {
      toast.error("Erro no servidor. Tente novamente mais tarde."); // Usar toast para notificação de erro
    } finally {
      setIsSubmitting(false);
      resetForm(); // Limpa todos os campos do formulário
    }
  }

  const location = useLocation();
  const contatoSectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = (sectionID: string) => {
    const sectionRef = sectionID === "contato" ? contatoSectionRef : null;
    if (sectionRef?.current) {
      setTimeout(() => {
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");
    if (section) {
      scrollToSection(section);
    }
  }, [location]);

  return (
    <div
      ref={contatoSectionRef}
      id="contato"
      className="w-full py-24 border-y border-slate-200 bg-slate-50"
    >
      <div className="container gap-10 flex flex-col md:flex-col justify-between">
        <div className="flex flex-col gap-1 w-full">
          <p className="text-brand-primary-600 font-semibold text-sm uppercase">
            Construindo caminhos
          </p>
          <h2 className="text-slate-950 font-semibold text-3xl">
            Trabalhe conosco
          </h2>
          <p className="text-slate-600 font-normal mt-2">
            Preencha os campos abaixo para entrar em contato.
          </p>
        </div>
        <div className="w-full">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="grid md:grid-cols-2 gap-4"
            >
              <div className="space-y-6">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nome completo</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Digite seu nome completo"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Digite seu e-mail</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Digite seu e-mail"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="self-start">
                      <FormLabel>Telefone</FormLabel>
                      <FormControl>
                        <Input type="text" placeholder="(  )" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>

              <div className="space-y-6 flex flex-col">
                <FormField
                  control={form.control}
                  name="file"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel htmlFor="file">Selecione o arquivo</FormLabel>
                      <FormControl>
                        <Input
                          ref={fileInputRef} // Adiciona a referência ao input
                          id="file"
                          type="file"
                          placeholder="File"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              const selectedFile = e.target.files[0];
                              setFile(selectedFile); // Atualiza o estado com o arquivo selecionado
                              field.onChange(selectedFile); // Pega o primeiro arquivo
                            }
                          }}
                          onBlur={field.onBlur}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="bio"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Escreva sua mensagem</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Escreva um pouco sobre você"
                          className="resize-y min-h-[72px]"
                          wrap="hard"
                          rows={1}
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <Button
                  type="submit"
                  size="sm"
                  disabled={isSubmitting}
                  className="gap-2 rounded-sm bg-brand-primary-950 hover:bg-brand-primary-900 md:max-w-28"
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </Button>
              </div>
            </form>
          </Form>
          <ToastContainer /> {/* Adicionar ToastContainer para exibir as notificações */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
