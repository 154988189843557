import { Breadcrumb, BreadcrumbList, BreadcrumbLink, BreadcrumbItem, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import MapTransition from "@/sections/map/map";

const PrivacyPolicy = () => {
  const timestamp = Date.now();

  const dataAtual = new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(timestamp);

  return (
    <>
      <div>
        {/* Cabeçalho com o breadcrumb e o título da página */}
        <div className="bg-brand-secondary-500 border-b-8 border-brand-primary-950">
          <div className="container w-full flex flex-col p-10 gap-6">
            <div>
              <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      asChild
                      className="text-white hover:text-brand-primary-950"
                    >
                      <a href="/">Home</a>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className="text-brand-primary-900" />
                  <BreadcrumbItem>
                    <BreadcrumbPage className="text-brand-primary-900">
                      Políticas de Privacidade
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
            </div>
            <div className="flex flex-col gap-3 text-white text-2xl md:text-4xl font-bold">
              Políticas de Privacidade
              <span className="text-slate-100 text-sm font-normal mb-3">
                {dataAtual}
              </span>
            </div>
          </div>
        </div>

        {/* Conteúdo da página de políticas */}
        <div className="container mx-auto py-12 px-4 lg:px-16">
          <div className="prose prose-lg max-w-none">
            <h2 className="font-bold text-3xl mb-6">Nossa Política de Privacidade</h2>
            <p>
              Nós, da Asphalt Pavimentação Asfáltica, levamos sua privacidade muito a sério. Esta política de privacidade explica como coletamos, usamos, e compartilhamos informações sobre você quando visita nosso site e utiliza nossos serviços.
            </p>

            <h3 className="font-bold text-2xl mt-6">Informações que Coletamos</h3>
            <p>
              Coletamos informações diretsamente de você, como quando você cria uma conta ou preenche formulários. Também podemos coletar automaticamente informações sobre sua navegação no nosso site, como seu endereço de IP, dispositivo, e navegador.
            </p>

            <h3 className="font-bold text-2xl mt-6">Uso das Informações</h3>
            <p>
              As informações que coletamos podem ser usadas para melhorar a funcionalidade do site, personalizar sua experiência, e para marketing e publicidade. Nós também utilizamos suas informações para manter nossos serviços seguros.
            </p>

            <h3 className="font-bold text-2xl mt-6">Compartilhamento de Informações</h3>
            <p>
              Podemos compartilhar suas informações com terceiros confiáveis, como parceiros de marketing e fornecedores de serviço, mas apenas quando necessário para fornecer nossos serviços e cumprir a legislação.
            </p>

            <h3 className="font-bold text-2xl mt-6">Seus Direitos</h3>
            <p>
              Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Entre em contato conosco se desejar exercer esses direitos.
            </p>

            <h3 className="font-bold text-2xl mt-6">Cookies</h3>
            <p>
              Usamos cookies para melhorar sua experiência em nosso site. Um cookie é um pequeno arquivo de dados que nosso site armazena em seu navegador. Ele ajuda a lembrar suas preferências e a coletar informações sobre sua navegação.
            </p>

            <h3 className="font-bold text-2xl mt-6">Alterações na Política</h3>
            <p>
              Podemos atualizar nossa política de privacidade de tempos em tempos. Quaisquer mudanças serão publicadas nesta página, então recomendamos que você a revise regularmente.
            </p>

            <h3 className="font-bold text-2xl mt-6">Contato</h3>
            <p>
              Se você tiver alguma dúvida sobre nossa política de privacidade, entre em contato conosco através do nosso <a href="/?section=contato" className="underline">formulário de contato</a>.
            </p>
          </div>
        </div>
      </div>

      {/* Seção de transição de mapa, como no exemplo do blog */}
      <MapTransition />
    </>
  );
};

export default PrivacyPolicy;
