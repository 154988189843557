import { Button } from "@/components/ui/button";
import { CalendarFold } from "lucide-react";
import { Link } from 'react-router-dom';

type Props = {
  title?: string;
  description?: string;
  className?: string;
  img?: string;
  data?: string;
  link?: string;
};

const CardBlog = ({ title, description, className, img, data, link }: Props) => {
  return (
    <div
      className={`border rounded-sm border-slate-200 flex flex-col hover:drop-shadow-lg hover:transition-all ${className}`}
      style={{ height: '100%' }} // Garante que o card ocupe 100% da altura
    >
      <Link to={link || '/'} className="block w-full h-48 overflow-hidden rounded-t-sm">
        <img
          src={img}
          className="w-full h-full object-contain"
          alt={title}
        />
      </Link>
      <div className="bg-white px-6 pt-3 pb-4 flex-1 flex flex-col">
        <h2 className="text-slate-950 font-semibold text-2xl">{title}</h2>
        <p className="text-sm text-slate-600 mt-3 line-clamp-3">
          {description}
        </p>
        <div className="mt-3 flex items-center gap-2">
          <CalendarFold size={16} className="text-slate-600" />
          <p className="text-slate-600 font-normal text-sm">{data}</p>
        </div>
        <div className="mt-auto">
          <Link to={link || '/'}>
            <Button
              variant="outline"
            className="mt-5 border-brand-primary-950 text-sm"
            >
              Ver mais
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardBlog;
