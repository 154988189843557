import { Newspaper } from "lucide-react";
import CardBlog from "./cardBlog";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// Definindo a interface para o blog
interface Blog {
  id: number;
  date: string;
  title: {
    rendered: string;
  };
  acf: {
    resumo: string;
    capa: number;
  };
  slug: string;
  imageUrl: string | null; // Permite que imageUrl seja null
}

// Função para buscar a URL da imagem pelo ID
const fetchImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(
      `https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem");
    }
    const data = await response.json();
    return data.source_url; // Retorna a URL da imagem
  } catch (error) {
    console.error(`Erro ao buscar a imagem para o ID ${imageId}:`, error);
    return null;
  }
};

const timestamp = Date.now();

const dataAtual = new Intl.DateTimeFormat("pt-BR", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
}).format(timestamp);

const BlogSection = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]); // Especificando o tipo do estado
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/blog");
        const blogsData: Blog[] = await response.json(); // Especificando o tipo dos dados recebidos

        // Para cada blog, buscar a URL da imagem da capa
        const blogsWithImages = await Promise.all(
          blogsData.map(async (blog) => {
            const imageUrl = await fetchImageUrl(blog.acf.capa);
            return { ...blog, imageUrl }; // Incluindo a URL da imagem no objeto do blog
          })
        );

        setBlogs(blogsWithImages);
      } catch (error) {
        console.error("Erro ao buscar dados do blog:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (isLoading) {
    return <p>Carregando blogs...</p>;
  }

  return (
    <>
      <div
        id="blogSection"
        className="mt-10 bg-brand-secondary-500 py-10 border-t-8 border-brand-primary-950"
      >
        <div className="container flex flex-col">
          <span className="text-white text-sm font-normal mb-3">{dataAtual}</span>
          <span className=" text-4xl text-white font-semibold inline-flex items-center gap-3">
            <Newspaper size={32} strokeWidth={1} />
            Notícias e blog
          </span>
          <span className="mt-3 text-white">
            Fique ligado em tudo que acontece no mundo da pavimentação asfáltica!
          </span>
        </div>
      </div>
      <div className="container mb-14 flex flex-col justify-end">
        <div className="py-12 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {blogs.map((blog) => (
            <CardBlog
              key={blog.id}
              img={blog.imageUrl || "/img/default.jpg"}
              title={blog.title.rendered}
              description={blog.acf.resumo}
              data={new Intl.DateTimeFormat("pt-BR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(new Date(blog.date))}
              link={`/blog/${blog.slug}`}
            />
          ))}
        </div>
        <span className="w-full text-right mt-6">
          <Button
            onClick={() => {
              navigate("/blog", { replace: true });
            }}
            className="flex w-full lg:font-medium bg-brand-primary-950 hover:bg-brand-primary-900"
          >
            Ver todas as notícias
          </Button>
        </span>
      </div>
    </>
  );
};

export default BlogSection;
