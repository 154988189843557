import OptionsCompliance from "./optionsCompliance";
import { useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from "react";

// Função para buscar os dados de Política de Compliance
const fetchComplianceData = async () => {
  try {
    const response = await fetch(
      "https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/home?slug=home"
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar os dados de compliance");
    }
    const data = await response.json();
    
    return data; // Retorna os dados recebidos
  } catch (error) {
    console.error(error);
    return null;
  }
};


const fetchEthicsCodeUrl = async (arquivoId: string): Promise<string | null> => {
  try {
    const response = await fetch(`https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${arquivoId}`);
    if (!response.ok) {
      throw new Error("Erro ao buscar o arquivo de Código de Ética e Conduta");
    }
    const data = await response.json();
    return data.source_url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const Compliance: React.FC = () => {
  const location = useLocation();
  const complianceSectionRef = useRef<HTMLDivElement | null>(null);
  const [ethicsCodeUrl, setEthicsCodeUrl] = useState<string | null>(null);
  const [complianceData, setComplianceData] = useState<any>(null); // Estado para armazenar os dados de compliance

  const scrollToSection = (sectionID: string) => {
    const sectionRef = sectionID === "compliance" ? complianceSectionRef : null;
    if (sectionRef?.current) {
      setTimeout(() => {
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");
    if (section) {
      scrollToSection(section);
    }
  }, [location]);


  useEffect(() => {
    const getComplianceData = async () => {
      const data = await fetchComplianceData();
      if (data && data.length > 0) {
        setComplianceData(data[0]); // Aqui garantimos que estamos pegando o primeiro item
      }
    };
  
    getComplianceData();
  }, []);

  useEffect(() => {
    const getEthicsCodeUrl = async () => {
      if (complianceData) {
        const arquivoId = complianceData.acf.politica_de_compliance.codigo_de_etica_e_conduta.arquivo;
        if (arquivoId) {
          const url = await fetchEthicsCodeUrl(arquivoId);
          setEthicsCodeUrl(url);
        }
      }      
    };

    getEthicsCodeUrl();
  }, [complianceData]); // Dependência adicionada para garantir que o efeito rode ao mudar complianceData


  // Verifica se complianceData está carregado e se possui a estrutura esperada
  if (!complianceData || !complianceData.acf) {
    return <div>Carregando...</div>; // Ou renderize um componente de loading
  }

  return (
    <div ref={complianceSectionRef} id="compliance" className="flex flex-wrap lg:flex-nowrap">
      <div className="flex flex-wrap py-14">
        <div className="container flex flex-wrap 2xl:ml-56 xl:ml-8 lg:pl-8 md:pl-8">
          <div className="flex w-full max-w-7xl flex-wrap flex-col space-y-10">
            <div className="flex flex-col">
              <p className="uppercase text-sm text-brand-secondary-500 font-semibold">
                  Políticas de compliance
              </p>
              <h2 className="text-3xl text-slate-950 font-semibold">
                {complianceData.acf.politicas_de_compliance.titulo}
              </h2>
              <p className="font-normal text-slate-600 mt-3">
                {complianceData.acf.politicas_de_compliance.descricao}
              </p>
            </div>
            <OptionsCompliance
              title="Conformidade legal"
              description={complianceData.acf.politicas_de_compliance.conformidade_legal}
              icon="shieldcheck"
            />
            <OptionsCompliance
              title="Ética e Integridade"
              description={complianceData.acf.politicas_de_compliance.etica_e_integridade}
              icon="scale"
            />
            <OptionsCompliance
              title="Segurança e Saúde"
              description={complianceData.acf.politicas_de_compliance.seguranca_e_saude}
              icon="heart"
            />
            <OptionsCompliance
              title="Proteção ambiental"
              description={complianceData.acf.politicas_de_compliance.protecao_ambiental}
              icon="shrub"
            />
            <OptionsCompliance
              title="Transparência"
              description={complianceData.acf.politicas_de_compliance.transparencia}
              icon="file"
            />
            <OptionsCompliance
              title="Treinamento"
              description={complianceData.acf.politicas_de_compliance.treinamento}
              icon="users"
            />
          </div>
        </div>
      </div>
      <div className="w-full max-w-7xl md:max-w-7xl lg:max-w-xl bg-brand-primary-950 py-14 flex">
        <div className="w-full pl-8 md:pl-12 flex flex-col justify-between h-auto">
          <div>
            <div className="text-brand-secondary-500 font-semibold text-sm uppercase">
              Política de compliance
            </div>
            <div className="text-white font-semibold text-2xl mt-1">
              Canal de denúncias
            </div>
            <div className="my-4">
              {complianceData.acf.politica_de_compliance.grupo_telefone.ativo && (
                <>
                  <div className="text-brand-secondary-500 font-semibold text-sm mt-4">
                    Via telefone
                  </div>
                  <div className="text-white font-medium text-lg">
                    <a href={`tel:${complianceData.acf.politica_de_compliance.grupo_telefone.telefone}`} className="">
                      {complianceData.acf.politica_de_compliance.grupo_telefone.telefone}
                    </a>
                  </div>
                </>
              )}

              {complianceData.acf.politica_de_compliance.grupo_email.ativo && (
                <>
                  <div className="text-brand-secondary-500 font-semibold text-sm mt-4">
                    Via e-mail
                  </div>
                  <div className="text-white font-medium text-lg">
                    <a href={`mailto:${complianceData.acf.politica_de_compliance.grupo_email.telefone}`} className="">
                      {complianceData.acf.politica_de_compliance.grupo_email.telefone}
                    </a>
                  </div>
                </>
              )}
              <a href="/canal-de-denuncia" className="inline-block mt-5 bg-brand-secondary-500 text-white rounded-sm font-medium gap-2 border border-white px-4 py-2 hover:bg-white hover:text-black transition-colors duration-200">
                  Via formulario
              </a>
            </div>
          </div>
          <div>
            {ethicsCodeUrl && (
              <div>
                <div className="text-white font-medium text-2xl mt-10 md:mt-10 lg:mt-1">
                  Código de ética e conduta
                </div>
                <a
                  style={{ display: "table-cell" }}
                  href={ethicsCodeUrl}
                  download
                  target="_blank"
                  className="inline-block bg-inherit text-white rounded-sm font-medium mt-[5px] gap-2 border border-white px-4 py-2 
                  hover:bg-white hover:text-black transition-colors duration-200">
                  Fazer download
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compliance;