import { useEffect, useState } from "react";
import TeamMembers from "./teamMembers";

// Função para buscar a URL da imagem pelo ID
const fetchImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(
      `https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem");
    }
    const data = await response.json();
    return data.source_url; // Retorna a URL da imagem
  } catch (error) {
    console.error(`Erro ao buscar a imagem para o ID ${imageId}:`, error);
    return null;
  }
};

const Team = () => {
  const [teamData, setTeamData] = useState<any[]>([]);
  const [teamDataInfo, setTeamDataInfo] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Fetch team data from the API
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch(
          "https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/empresa?slug=empresa"
        );
        const data = await response.json();
        const equipeInfo = data[0].acf.nossa_equipe.descricao;
        const equipe = data[0].acf.nossa_equipe.equipe;

        // Buscar URLs das imagens para cada membro da equipe
        const equipeComImagens = await Promise.all(
          equipe.map(async (member: any) => {
            // Verifique se o ID da imagem é válido
            if (member.foto) {
              const imageUrl = await fetchImageUrl(member.foto);
              return {
                ...member,
                imageUrl: imageUrl || "/img/avatar/default.png", // Usa uma imagem default se não encontrar
              };
            }
            return {
              ...member,
              imageUrl: "/img/avatar/default.png", // Se não tiver ID de imagem, usar imagem default
            };
          })
        );

        setTeamData(equipeComImagens);
        setTeamDataInfo(equipeInfo);
      } catch (error) {
        console.error("Erro ao buscar dados da equipe:", error);
      } finally {
        setIsLoading(false); // Define o estado de carregamento como falso após a conclusão
      }
    };

    fetchTeamData();
  }, []);  

  if (isLoading) {
    return <p>Carregando equipe...</p>; // Exibe mensagem enquanto os dados estão carregando
  }
  return (
    <div className="container pt-10 pb-20 md:py-24 flex flex-col gap-10 md:flex-row justify-between">
      <div className="flex flex-col gap-3 max-w-xl">
        <h2 className="text-3xl font-semibold text-slate-950">
          Conheça nossa equipe
        </h2>
        <p className="text-base text-slate-600 leading-6">
          {teamDataInfo}
        </p>
      </div>
      <div className="flex flex-wrap max-w-7xl gap-10 justify-center">
        {teamData.length > 0 ? (
          teamData.map((member, index) => (
            <div key={index} className="w-full md:w-1/2 lg:w-1/3 flex flex-col space-y-10">
              <TeamMembers
                avatarImg={member.imageUrl}
                avatarFallBack={member.nome[0]}
                name={member.nome}
                position={member.cargo}
                email={member.email}
              />
            </div>
          ))
        ) : (
          <p>Nenhum membro da equipe disponível.</p>
        )}
      </div>
    </div>
  );  
};

export default Team;
