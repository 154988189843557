import { Separator } from "@/components/ui/separator";
import { Link as LinkRouter } from "react-router-dom";

const Footer = () => {

  const handleScroll = () => {
    const secao = document.getElementById("hero");
    if (secao) {
      secao.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="bg-brand-primary-950 pt-6 pb-10">
      <div className="container">
        <div className="flex gap-4 justify-center">
          <div
            className="min-w-96 text-center space-y-2 md:space-x-0
           md:text-right text-white text-sm font-light">
            <div className="flex justify-center md:justify-end">
              <img src="/img/logo-default.png" alt="" className="h-auto w-56" />
            </div>
            <div className="flex flex-col space-y-2">
              <a href="https://www.google.com/maps/place/R.+Mal.+Deodoro,+893+-+2º+andar,+sala+12+-+Centro,+Prudentópolis+-+PR,+84400-000/@-25.2126995,-50.9905012,15z/data=!3m1!4b1!4m5!3m4!1s0x94e8ea57e266d3df:0x7fee94e520135804!8m2!3d-25.2127193!4d-50.9802015?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D">
                  Rua Marechal Deodoro, nº 893, 2º andar, sala 12
              </a>
              <a href="https://www.google.com/maps/place/R.+Mal.+Deodoro,+893+-+2º+andar,+sala+12+-+Centro,+Prudentópolis+-+PR,+84400-000/@-25.2126995,-50.9905012,15z/data=!3m1!4b1!4m5!3m4!1s0x94e8ea57e266d3df:0x7fee94e520135804!8m2!3d-25.2127193!4d-50.9802015?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D">
                  84400-000
              </a>
              <a href="https://www.google.com/maps/place/R.+Mal.+Deodoro,+893+-+2º+andar,+sala+12+-+Centro,+Prudentópolis+-+PR,+84400-000/@-25.2126995,-50.9905012,15z/data=!3m1!4b1!4m5!3m4!1s0x94e8ea57e266d3df:0x7fee94e520135804!8m2!3d-25.2127193!4d-50.9802015?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D">
                  Prudentópolis, Paraná
              </a>
              <a href="tel:+5542998730129">(42) 99873-0129</a>
            </div>
          </div>
          <Separator
            orientation="vertical"
            className="h-auto text-white opacity-10 hidden md:flex"
          />
          <div className="min-w-96 mt-auto hidden md:flex md:flex-col">
            <p className="text-brand-secondary-500 font-medium text-lg pb-1">
              Acesso rápido
            </p>
            <ul className="text-white font-light">
              <li>
                <LinkRouter
                  to="/"
                  onClick={handleScroll}
                  className="hover:cursor-pointer hover:underline hover:decoration-solid"
                >
                  Início
                </LinkRouter>
              </li>
              <li>
                <LinkRouter
                  to="empresa"
                  onClick={handleScroll}
                  className="hover:cursor-pointer hover:underline hover:decoration-solid"
                >
                  Empresa
                </LinkRouter>
              </li>
              <li>
                <LinkRouter 
                  to="/?section=servicos"
                  className="hover:cursor-pointer hover:underline hover:decoration-solid"
                >
                  Serviços
                </LinkRouter>
              </li>
              <li>
                <LinkRouter 
                  to="/?section=compliance"
                  className="hover:cursor-pointer hover:underline hover:decoration-solid"
                >
                  Compliance
                </LinkRouter>
              </li>
              <li>  
                <LinkRouter 
                  to="/?section=contato"
                  className="hover:cursor-pointer hover:underline hover:decoration-solid"
                >
                  Contato
                </LinkRouter>
              </li>
            </ul>
          </div>
        </div>
        <Separator className="mt-10 mb-10 opacity-10" />
        <span className="flex justify-center gap-4 text-white font-light text-xs">
          <p className="hidden md:block">
            © 2024 Asphalt Pavimentação Asfáltica. Todos os direitos reservados.
          </p>
          <p className="block md:hidden text-center">
            © 2024 Asphalt Pavimentação Asfáltica. <br /> Todos os direitos reservados.
          </p>
          <Separator orientation="vertical" className="h-auto opacity-10 hidden md:flex" />
          <a href="/politica-de-privacidade" className="hover:underline hover:decoration-solid">
            Política de Privacidade
          </a>
          <Separator orientation="vertical" className="h-auto opacity-10 hidden md:flex" />
          <a href="/politica-de-privacidade" className="hover:underline hover:decoration-solid">
            Termos de Uso
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
