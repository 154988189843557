import { useState } from "react";

const data = [
  {
    imgelink:
      "/img/asphalt/galeria/1.jpeg"
  },
  {
    imgelink:
      "/img/asphalt/galeria/2.jpeg"
  },
  {
    imgelink:
      "/img/asphalt/galeria/3.jpeg"
  },
  {
    imgelink:
      "/img/asphalt/galeria/4.jpeg"
  },
  {
    imgelink:
      "/img/asphalt/galeria/5.jpeg"
  },
  {
    imgelink:
      "/img/asphalt/galeria/6.jpeg"
  },
  {
    imgelink:
      "/img/asphalt/galeria/7.jpeg"
  },
];

const AboutGalery = () => {
  const [active, setActive] = useState(
    "/img/asphalt/galeria/1.jpeg"
  );
  return (
    <div className="col-span-2 md:col-span-6 lg:col-span-6">
      <div className="grid gap-4">
        <div>
          <img
            className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
            src={active}
            alt=""
          />
        </div>
        <div className="grid grid-cols-5 gap-4">
          {data.map(({ imgelink }, index) => (
            <div key={index}>
              <img
                onClick={() => setActive(imgelink)}
                src={imgelink}
                className="object-cover object-center h-20 max-w-full rounded-lg cursor-pointer"
                alt="gallery-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutGalery;
