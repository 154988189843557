import React, { useEffect, useState } from "react";
import NumberTicker from "@/components/magicui/number-ticker";
import { Separator } from "@/components/ui/separator";
import { ChartNoAxesCombined } from "lucide-react";

// Simples loader
const Loader = () => (
  <div className="flex justify-center items-center h-full">
    <p className="text-slate-950">Carregando...</p>
  </div>
);

// Interface para os dados retornados da API
interface StatsData {
  acf: {
    nossos_numeros: {
      asfalto_aplicado: number;
      projetos_executados: number;
      colaboradores: number;
    };
  };
}

const Stats: React.FC = () => {
  const [data, setData] = useState<StatsData | null>(null);
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState<Error | null>(null); // Estado de erro

  useEffect(() => {
    // Fazer a chamada da API para obter os números
    fetch("https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/home?slug=home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar dados");
        }
        return response.json();
      })
      .then((data: StatsData[]) => {
        setData(data[0]); // Assumindo que o resultado seja um array, pegamos o primeiro item
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  // Exibir loader enquanto os dados estão sendo carregados
  if (loading) {
    return <Loader />;
  }

  // Exibir mensagem de erro se algo der errado
  if (error) {
    return (
      <div className="text-slate-950 flex justify-center items-center h-full">
        <p>Erro ao carregar os dados: {error.message}</p>
      </div>
    );
  }

  // Extração dos valores da API
  const { asfalto_aplicado, projetos_executados, colaboradores } = data?.acf.nossos_numeros || {
    asfalto_aplicado: 0,
    projetos_executados: 0,
    colaboradores: 0,
  };

  return (
    <div className="mt-16 mb-24">
      <div className="container">
        <div className="justify-center flex items-center gap-2">
          <ChartNoAxesCombined size={32} className="text-brand-secondary-500" />
          <div className="text-4xl text-center font-semibold tracking-tight text-slate-950">
            Nossos números
          </div>
        </div>
        <p className="justify-center flex items-center mt-3 mb-14 text-center text-slate-950 font-normal text-base">
          Veja como nossos números comprovam nosso compromisso com qualidade e
          inovação!
        </p>
      </div>

      <div className="flex flex-wrap flex-col md:flex-row gap-6 justify-center items-center ">
        <div className="flex flex-col items-center">
          <p className="text-5xl tracking-tighter font-semibold text-gray-950">
            <NumberTicker value={asfalto_aplicado} /> mi
          </p>
          <p className="italic leading-7 font-normal text-slate-600">
            m² de asfalto aplicado
          </p>
        </div>
        <Separator
          orientation="vertical"
          className="h-20 mx-8 hidden md:flex lg:flex"
        ></Separator>
        <Separator
          orientation="horizontal"
          className="w-64 mx-8 md:hidden lg:hidden"
        ></Separator>

        <div className="flex flex-col items-center">
          <h2 className="whitespace-pre text-5xl font-semibold tracking-tighter text-black">
            +<NumberTicker value={projetos_executados} />
          </h2>
          <p className="italic leading-7 font-normal text-slate-600">
            projetos executados
          </p>
        </div>
        <Separator
          orientation="vertical"
          className="h-20 mx-8 hidden md:flex lg:flex"
        ></Separator>
        <Separator
          orientation="horizontal"
          className="w-64 mx-8 md:hidden lg:hidden"
        ></Separator>
        <div className="flex flex-col items-center">
          <h2 className="whitespace-pre text-5xl font-semibold tracking-tighter text-black">
            +<NumberTicker value={colaboradores} />
          </h2>
          <p className="italic leading-7 font-normal text-slate-600">
            colaboradores
          </p>
        </div>
      </div>
    </div>
  );
};

export default Stats;