import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface BlogPost {
  id: number;
  date: string;
  title: {
    rendered: string;
  };
  acf: {
    resumo: string;
    subtitulo: string;
    capa: number;
    conteudo: string;
  };
}

const fetchImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(
      `https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem");
    }
    const data = await response.json();
    return data.source_url; // Retorna a URL da imagem
  } catch (error) {
    console.error(`Erro ao buscar a imagem para o ID ${imageId}:`, error);
    return null;
  }
};

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>(); // Captura o parâmetro "slug" da URL
  const [post, setPost] = useState<BlogPost | null>(null); // Estado para armazenar o post
  const [loading, setLoading] = useState(true); // Estado para controle de loading
  const [imageUrl, setImageUrl] = useState<string | null>(null); // Estado para armazenar a URL da imagem

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/blog?slug=${slug}`);
        const data: BlogPost[] = await response.json();

        if (data.length > 0) {
          setPost(data[0]); // Armazena o primeiro post encontrado
          
          // Busca a URL da imagem de capa
          const imgUrl = await fetchImageUrl(data[0].acf.capa);
          setImageUrl(imgUrl); // Atualiza o estado com a URL da imagem
        } else {
          console.error('Post não encontrado');
        }
      } catch (error) {
        console.error('Erro ao buscar o post:', error);
      } finally {
        setLoading(false); // Finaliza o loading após a requisição
      }
    };

    fetchPost();
  }, [slug]); // Executa o efeito sempre que o slug mudar

  // Simulando fallback para carregamento
  if (loading) {
    return <div className="text-center text-gray-500">Carregando...</div>;
  }

  // Se não encontrar o post, pode exibir uma mensagem
  if (!post) {
    return <div className="text-center text-gray-500">Post não encontrado.</div>;
  }

  return (
    <>
      <div className="container py-8 md:py-12">
        <header className="py-4">
          <div className="container mx-auto px-4">
            <h1 className="text-3xl font-bold">{post.title.rendered}</h1>
            <p className="text-xl mt-2">{post.acf.subtitulo}</p>
          </div>
        </header>

        <div
          className="w-full h-64 md:h-96 bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${imageUrl || '/img/default-placeholder.jpg'})` }} // Usa a URL da imagem se disponível, ou uma imagem padrão
        />

        <div className="container mx-auto px-4 py-8">
          <div className="grid grid-cols-1 gap-8">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <article className="p-6">
                <p className="text-base leading-relaxed text-slate-950 lg:text-justify">
                  {post.acf.conteudo} {/* Conteúdo do post */}
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
