import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const CookieConsent = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Verifica se o cookie "userConsent" já foi definido
    const userConsent = Cookies.get("userConsent");
    if (!userConsent) {
      setShowPopup(true); // Exibe o popup se o consentimento não foi dado
    }
  }, []);

  const handleAccept = () => {
    // Define um cookie que expira em 365 dias
    Cookies.set("userConsent", "true", { expires: 365 });
    setShowPopup(false); // Esconde o popup após aceitação
  };

  const handleDecline = () => {
    // Aqui você pode adicionar qualquer ação caso o usuário recuse
    setShowPopup(false); // Esconde o popup sem salvar o consentimento
  };

  if (!showPopup) {
    return null; // Não exibe nada se o usuário já aceitou ou recusou
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 z-50">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <p className="mb-4 md:mb-0">
          Nós usamos cookies para melhorar sua experiência. Ao continuar navegando,
          você concorda com nossa{" "}
          <a href="/politica-de-privacidade" className="underline">
            política de privacidade
          </a>.
        </p>
        <div className="flex flex-row items-center justify-start gap-4">
          <button
            onClick={handleAccept}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          >
            Aceitar
          </button>
          <button
            onClick={handleDecline}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
          >
            Recusar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
