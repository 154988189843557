import { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbLink,
  BreadcrumbItem,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import CardBlog from "@/sections/blog/cardBlog";
import MapTransition from "@/sections/map/map";

// Definindo a interface para o blog
interface Blog {
  id: number;
  date: string;
  title: {
    rendered: string;
  };
  acf: {
    resumo: string;
    capa: number;
  };
  slug: string;
  imageUrl: string | null; // Permite que imageUrl seja null
}

// Função para buscar a URL da imagem pelo ID
const fetchImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(
      `https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem");
    }
    const data = await response.json();
    return data.source_url; // Retorna a URL da imagem
  } catch (error) {
    console.error(`Erro ao buscar a imagem para o ID ${imageId}:`, error);
    return null;
  }
};

const Blog = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]); // Estado para armazenar os blogs
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/blog");
        const blogsData: Blog[] = await response.json(); // Especificando o tipo dos dados recebidos

        // Para cada blog, buscar a URL da imagem da capa
        const blogsWithImages = await Promise.all(
          blogsData.map(async (blog) => {
            const imageUrl = await fetchImageUrl(blog.acf.capa);
            return { ...blog, imageUrl }; // Incluindo a URL da imagem no objeto do blog
          })
        );

        setBlogs(blogsWithImages);
      } catch (error) {
        console.error("Erro ao buscar dados do blog:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const timestamp = Date.now();

  const dataAtual = new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(timestamp);

  return (
    <>
      <div>
        <div className="bg-brand-secondary-500 border-b-8 border-brand-primary-950">
          <div className="container w-full flex flex-col p-10 gap-6">
            <div>
              <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      asChild
                      className="text-white hover:text-brand-primary-950"
                    >
                      <a href="/">Home</a>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className="text-brand-primary-900" />
                  <BreadcrumbItem>
                    <BreadcrumbPage className="text-brand-primary-900">
                      Blog
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
            </div>
            <div className="flex flex-col gap-3 text-white text-2xl md:text-4xl font-bold">
              Blog
              <span className="text-slate-100 text-sm font-normal mb-3">
                {dataAtual}
              </span>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center py-12 text-white">Carregando blogs...</div>
        ) : (
          <div className="py-12 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-16 mb-24 px-16">
            {blogs.map((blog) => (
              <CardBlog
                key={blog.id}
                img={blog.imageUrl || "/img/default.jpg"}
                title={blog.title.rendered}
                description={blog.acf.resumo}
                data={new Intl.DateTimeFormat("pt-BR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }).format(new Date(blog.date))}
                link={`/blog/${blog.slug}`}
              />
            ))}
          </div>
        )}
      </div>
      <MapTransition />
    </>
  );
};

export default Blog;
