import { useEffect, useState } from "react";
import { Home, Mail } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { NavItem, NavScroll } from "./navItems";
import SheetMenu from "./sheetMenu";
import { useNavigate } from "react-router-dom";

// Interface para os dados da API
interface HeaderData {
  endereco: {
    endereco_completo: string;
    logradouro: string;
    cep: string;
    cidade_estado: string;
    link_maps: {
      url: string;
    };
  };
  telefone: string;
  email: string;
}

const Header = () => {
  const navigate = useNavigate();
  const [headerData, setHeaderData] = useState<HeaderData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // Buscar os dados da API
    fetch("https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/home?slug=home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar dados do cabeçalho");
        }
        return response.json();
      })
      .then((data) => {
        const fetchedData = data[0].acf.endereco_cabecalho;
        setHeaderData(fetchedData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleGoTo = () => {
    navigate("/");
  };

  const goToPage = (page: string, sectionID: string) => {
    setTimeout(() => {
      navigate(`${page}?section=${sectionID}`);
    }, 300);
  };

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    return <p>Erro: {error.message}</p>;
  }

  return (
    <div>
      <div className="hidden md:flex bg-brand-primary-50 text-sm font-normal text-brand-primary-950 border-b border-slate-200">
        <div className="container py-3">
          <div className="flex flex-wrap items-center justify-center gap-4">
            {headerData && (
              <>
                <div className="flex items-center">
                  <Home strokeWidth={1} className="w-4 h-4 mr-2" />
                  <a href={headerData.endereco.link_maps.url}>
                    {headerData.endereco.endereco_completo}
                  </a>
                </div>
                <Separator
                  orientation="vertical"
                  className="h-4 bg-brand-primary-950"
                />
                <div className="flex items-center">
                  <Mail strokeWidth={1} className="w-4 h-4 mr-2" />
                  <a href={`mailto:${headerData.email}`}>{headerData.email}</a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <header className="bg-white border-b border-slate-200 py-6">
        <div className="container">
          <div className="flex flex-wrap items-center justify-between md:justify-between lg:justify-between gap-3">
            <div onClick={handleGoTo}>
              <img
                src="/img/logo-invert.png"
                alt="Logotipo Asphalt"
                className="w-36 md:w-48 h-full object-contain cursor-pointer"
              />
            </div>
            <div className="lg:hidden xl:hidden">
              <SheetMenu />
            </div>
            <nav className="hidden md:hidden lg:flex">
              <ul className="flex flex-wrap items-center justify-center py-2 px-4">
                <li className="flex flex-wrap items-center justify-center gap-2 py-2 px-4">
                  <NavItem classname="cursor-pointer" to="/">
                    Início
                  </NavItem>
                  <NavItem classname="cursor-pointer" to="empresa">
                    Empresa
                  </NavItem>
                  <NavScroll
                    onClick={() => goToPage("/", "servicos")}
                    to="servicos"
                    classname="cursor-pointer"
                  >
                    Serviços
                  </NavScroll>
                  <NavScroll
                    onClick={() => goToPage("/", "compliance")}
                    to="compliance"
                    classname="cursor-pointer"
                  >
                    Compliance
                  </NavScroll>
                  <NavItem classname="cursor-pointer" to="blog">
                    Blog
                  </NavItem>
                  <NavScroll
                    onClick={() => goToPage("/", "contato")}
                    to="contato"
                    classname="cursor-pointer"
                  >
                    Contato
                  </NavScroll>
                </li>
              </ul>
            </nav>
            <Button
              onClick={() => goToPage("/", "contato")}
              className="hidden md:hidden lg:flex lg:font-medium bg-brand-primary-900 hover:bg-brand-primary-950"
            >
              Entrar em contato
            </Button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
