import { useEffect, useState } from "react";

const Principles = () => {
  const [mission, setMission] = useState<string>("");
  const [vision, setVision] = useState<string>("");
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/empresa?slug=empresa"
        );
        const data = await response.json();

        // Atualiza os estados com os dados da API
        setMission(data[0].acf.missao);
        setVision(data[0].acf.visao);
        setValues(data[0].acf.valores.map((valor: any) => valor.valores));
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      } finally {
        setIsLoading(false); // Finaliza o carregamento
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <p>Carregando dados...</p>;
  }

  return (
    <>
      <div className="grid md:grid-cols-4">
        <div className="col-span-2 py-14 bg-brand-secondary-500 max-w pr-12">
          <div
            className="flex flex-col gap-3 ml-8 2xl:ml-64 xl:ml-12
           lg:pl-0"
          >
            <h2 className="text-white text-2xl font-semibold">Missão</h2>
            <p className="text-white text-base font-light">{mission}</p>
          </div>
        </div>
        <div className="col-span-2 py-14 bg-brand-primary-950">
          <div className="flex flex-col gap-3 ml-8 mr-8 lg:pl-8 2xl:mr-56 xl:mr-8 lg:pr- md:pr-8">
            <h2 className="text-white text-2xl font-semibold">Visão</h2>
            <p className="text-white text-base font-light">{vision}</p>
          </div>
        </div>
      </div>
      <div className=" bg-slate-100 border-b border-slate-200">
        <div className="container flex flex-col py-14 gap-3">
          <h2 className="text-slate-950 text-2xl font-semibold">Valores</h2>
          <ul className="text-slate-600 list-disc pl-4">
            {values.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Principles;
