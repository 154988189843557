import { AvatarImage, Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Mail } from "lucide-react";

type Props = {
  avatarImg: string;
  avatarFallBack: string;
  name?: string;
  position?: string;
  email?: string;
};

const shortenEmail = (email: string): string => {
  const maxLength = 20; 
  return email.length > maxLength ? `${email.slice(0, maxLength)}...` : email;
};

const TeamMembers = ({
  avatarImg,
  name,
  position,
  email,
  avatarFallBack,
}: Props) => {
  return (
    <div className="flex flex-row gap-2 md:items-center">
      <Avatar className="h-14 w-14 md:h-16 md:w-16">
        <AvatarImage
          src={avatarImg}
          alt={`Imagem do membro do time ${name}`}
          className="object-contain"
        />
        <AvatarFallback>{avatarFallBack}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col gap-0 flex-grow"> {/* Adicionei flex-grow */}
        <h3 className="font-medium text-lg text-slate-950">{name}</h3>
        <p className="font-normal text-sm text-slate-800">{position}</p>
        <span className="inline-flex items-center gap-2 mt-1 md:mt-3">
          <Mail size={14} className="text-brand-primary-600 " />
          {email && (
            <a href={`mailto:${email}`} className="font-normal text-sm italic text-slate-600">
              {shortenEmail(email)}
            </a>
          )}
        </span>
      </div>
    </div>
  );
};

export default TeamMembers;
