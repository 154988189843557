const MapTransition = () => {
  return (
    <div
      className="w-full h-200px
     bg-cover"
    >
      <img src="/img/map.jpg" alt="Mapa 2D em tons de cinza" />
    </div>
  );
};

export default MapTransition;
