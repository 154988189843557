import { Breadcrumb, BreadcrumbList, BreadcrumbLink, BreadcrumbItem, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import MapTransition from "@/sections/map/map";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { ToastContainer, toast } from "react-toastify"; 
import 'react-toastify/dist/ReactToastify.css'; 

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_FILES_TYPES = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png"
];

const formSchema = z.object({
  message: z
    .string({ message: "A mensagem é obrigatória" })
    .min(10, { message: "A mensagem precisa ter mais que 10 caracteres." }),
  file: z
    .instanceof(File, { message: "O arquivo é obrigatório" })
    .refine((file) => file.size <= MAX_FILE_SIZE, "Tamanho máximo de 5mb")
    .refine(
      (file) => ACCEPTED_FILES_TYPES.includes(file.type),
      "Formato de arquivo inválido"
    ),
});

const CanalDenuncias = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  const resetForm = () => {
    form.reset();
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  async function onSubmit(data: z.infer<typeof formSchema>) {
    setIsSubmitting(true);
    
    const formData = new FormData();
    formData.append("message", data.message);
    if (file) {
      formData.append("file", file, file.name);
    }

    try {
      const response = await axios.post(
        "https://api.asphaltpavimentacao.com.br/wp-json/custom/v1/send-reporting",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Email enviado com sucesso!");
      } else {
        toast.error("Erro ao enviar o email. Tente novamente.");
      }
    } catch (error) {
      toast.error("Erro no servidor. Tente novamente mais tarde.");
    } finally {
      setIsSubmitting(false);
      resetForm();
    }
  }

  return (
    <>
    <div>
        {/* Cabeçalho com o breadcrumb e o título da página */}
        <div className="bg-brand-secondary-500 border-b-8 border-brand-primary-950">
          <div className="container w-full flex flex-col p-10 gap-6">
            <div>
              <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      asChild
                      className="text-white hover:text-brand-primary-950"
                    >
                      <a href="/">Home</a>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className="text-brand-primary-900" />
                  <BreadcrumbItem>
                    <BreadcrumbPage className="text-brand-primary-900">
                      Canal de denúncias
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
            </div>
            <div className="flex flex-col gap-3 text-white text-2xl md:text-4xl font-bold">
              Canal de denúncias
              <span className="text-slate-100 text-sm font-normal mb-3">
              Asphalt Pavimentação Asfáltica Ltda
              </span>
            </div>
          </div>
        </div>
      <div className="container mx-auto py-12 px-4 lg:px-16">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mensagem</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Escreva sua mensagem aqui"
                      className="resize-y min-h-[72px]"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem>
                  <FormLabel htmlFor="file">Anexar arquivo</FormLabel>
                  <FormControl>
                    <Input
                      ref={fileInputRef}
                      id="file"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          const selectedFile = e.target.files[0];
                          setFile(selectedFile);
                          field.onChange(selectedFile);
                        }
                      }}
                      onBlur={field.onBlur}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button
              type="submit"
              size="sm"
              disabled={isSubmitting}
              className="gap-2 rounded-sm bg-brand-primary-950 hover:bg-brand-primary-900"
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </Button>
          </form>
        </Form>
        <ToastContainer />
      </div>
      </div>
    {/* Seção de transição de mapa, como no exemplo do blog */}
    <MapTransition />
    </>
  );
};

export default CanalDenuncias;
