import { useRef, useState } from "react";

type Props = {
  title?: string;
  description?: string;
  img?: string;
};

const CardServicos = ({ title, description, img}: Props) => {
  const [isHover, setIsHover] = useState(false);
  const hoverRef = useRef(null);

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      ref={hoverRef}
      className="border-b border-slate-200 h-auto w-auto py-6 pr-28 pl-8 lg:pl-12 md:pl-12 transition ease-in duration-700"
    >
      <div className={isHover ? "flex flex-col md:flex-row md:justify-between" : ""}>
        <div className="space-y-6 flex flex-col">
          <div className={isHover ? "max-w-7xl md:max-w-lg" : "max-w-7xl md:max-w-max"}>
            <h2 className="text-slate-950 font-semibold text-2xl">{title}</h2>
            <p className="font-normal text-sm text-slate-600 mt-3 lg:text-justify">{description}</p>
          </div>
          {/* <div>
            <Button
              size="sm"
              variant="outline"
              className={`bg-inherit rounded-sm font-medium w-auto gap-2 border ${
                isHover ? "border-gray-500 text-gray-500" : "border-brand-primary-950 text-brand-primary-950"
              }`}
            >
              {isHover ? "Em construção" : "Saiba mais"}
              <ArrowRight
                size={16}
                strokeWidth={2}
                className={`w-5 h-5 ${isHover ? "hidden" : ""}`}
              ></ArrowRight>
            </Button>
          </div> */}
        </div>

        {/* Fixed size container for the image */}
        <div className="relative h-auto md:h-auto md:w-80 hidden md:flex ml-[20px]">
            <div className="relative w-full h-full">
              <img
                src={img}
                alt="Serviço"
                className={`border bg-cover bg-no-repeat border-slate-200 rounded-md w-full h-full object-cover transition-transform transition-opacity duration-500 ease-in-out absolute top-0 left-0 ${
                  isHover ? "opacity-100 scale-105" : "opacity-0 scale-100"
                }`}          
              />
            </div>
        </div>
      </div>
    </div>
  );
};

export default CardServicos;
