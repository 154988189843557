import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";

// Função para buscar a URL da imagem de um parceiro
const fetchPartnerImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(`https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`);
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem do parceiro");
    }
    const data = await response.json();
    return data.source_url; // Retorna a URL da imagem
  } catch (error) {
    console.error("Erro ao buscar a imagem do parceiro:", error);
    return null;
  }
};

export function PartnersCard() {
  const [partnerImages, setPartnerImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // Função para buscar os IDs dos parceiros e depois buscar suas imagens
    const fetchPartnerImages = async () => {
      try {
        // Primeira requisição para obter os IDs das imagens dos parceiros
        const response = await fetch(`https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/home?slug=home`);
        if (!response.ok) {
          throw new Error("Erro ao buscar dados de parceiros");
        }
        const data = await response.json();
        const partnerIds: number[] = data[0]?.acf?.parceiros || [];

        // Para cada ID, buscar a URL da imagem
        const imagePromises = partnerIds.map((id) => fetchPartnerImageUrl(id));
        const images = await Promise.all(imagePromises);

        // Filtra URLs não nulas e atualiza o estado
        setPartnerImages(images.filter((url) => url !== null) as string[]);
        setLoading(false);
      } catch (error) {
        setError(error as Error);  // Casting explícito do erro para 'Error'
        setLoading(false);
      }
    };

    fetchPartnerImages();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar os parceiros: {error.message}</div>;
  }

  return (
    <Carousel
      plugins={[
        Autoplay({
          delay: 2000,
          stopOnInteraction: true,
          stopOnMouseEnter: true,
        }),
      ]}
      className="w-full h-full max-w-full justify-center items-center"
      opts={{ loop: true, align: "start" }}
    >
      <CarouselContent>
        {partnerImages.map((imageUrl, index) => (
          <CarouselItem
            key={index}
            className="basis-1/2 md:basis-1/4 lg:basis-1/6 w-auto flex"
          >
            <div className="w-64 h-auto">
              <img
                src={imageUrl}
                alt={`Parceiro ${index + 1}`}
                className="h-full w-full border border-slate-200 rounded-sm"
              />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="visible md:visible lg:invisible ml-8" />
      <CarouselNext className="visible md:visible lg:invisible mr-8" />
    </Carousel>
  );
}