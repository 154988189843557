import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import { NavItem, NavScroll } from "./navItems";
import { Separator } from "@/components/ui/separator";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SheetMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSheetClose = () => {
    setIsOpen(false);
  };

  const goToPage = (page: string, sectionID: string) => {
    setIsOpen(false);
    setTimeout(() => {
      navigate(`${page}?section=${sectionID}`);
    }, 300);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild aria-describedby="Sidemenu">
        <Button
          variant="default"
          className="font-medium bg-brand-primary-900 hover:bg-brand-primary-950 gap-4"
        >
          Menu
          <Menu size={16} className="text-white" />
        </Button>
      </SheetTrigger>
      <SheetContent className="">
        <SheetHeader>
          <SheetTitle className="text-left pl-2">Menu</SheetTitle>
          <SheetDescription />
          <Separator className="opacity-50" />
        </SheetHeader>
        <ul className=" items-center justify-center">
          <li className="items-center justify-center gap-2">
            <NavItem
              onClick={handleSheetClose}
              to="Home"
              classname="hover:bg-brand-primary-50 w-full rounded-md"
            >
              Início
            </NavItem>
            <NavItem
              onClick={handleSheetClose}
              to="Empresa"
              classname="hover:bg-brand-primary-50 w-full rounded-md"
            >
              Empresa
            </NavItem>
            <NavItem
              onClick={() => goToPage("/", "servicos")}
              classname="hover:bg-brand-primary-50 w-full rounded-md"
            >
              Serviços
            </NavItem>
            <NavScroll
              onClick={handleSheetClose}
              to="compliance"
              classname="hover:bg-brand-primary-50 w-full rounded-md"
            >
              Compliance
            </NavScroll>
            <NavItem
              onClick={handleSheetClose}
              to="blog"
              classname="hover:bg-brand-primary-50 w-full rounded-md"
            >
              Blog
            </NavItem>
            <NavScroll
              onClick={handleSheetClose}
              to="contato"
              classname="hover:bg-brand-primary-50 w-full rounded-md"
            >
              Contato
            </NavScroll>
          </li>
        </ul>
        <SheetFooter>
          <SheetClose asChild>
            <Button 
              onClick={() => goToPage("/", "contato")}
              className="font-medium bg-brand-primary-950 hover:bg-brand-primary-900 mt-6">
              Entrar em contato
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SheetMenu;
