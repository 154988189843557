import React, { useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ArrowRight, CircleChevronDownIcon, Construction } from "lucide-react";
import { useNavigate } from "react-router-dom";

// Função para buscar a URL da imagem pelo ID
const fetchImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(`https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`);
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem");
    }
    const data = await response.json();
    return data.source_url; // Retorna a URL da imagem
  } catch (error) {
    console.error("Erro ao buscar a imagem:", error);
    return null;
  }
};

// Simples loader
const Loader = () => (
  <div className="flex justify-center items-center h-full">
    <p className="text-white">Carregando...</p>
  </div>
);

// Interface para o tipo de dados da API
interface ApiResponse {
  acf: {
    header: {
      destaque?: string;
      titulo?: string;
      descricao?: string;
      imagem_de_fundo?: number; // ID da imagem de fundo
    };
    nossos_numeros?: {
      asfalto_aplicado: number;
      projetos_executados: number;
      colaboradores: number;
    };
  };
}

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<ApiResponse | null>(null); // Tipando o estado
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState<Error | null>(null); // Estado de erro
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | null>(null); // Estado para armazenar a URL da imagem
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    // Fazer a chamada da API para obter o conteúdo
    fetch("https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/home?slug=home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar dados");
        }
        return response.json();
      })
      .then(async (data: ApiResponse[]) => {
        const fetchedData = data[0]; // Assumindo que os dados retornam como um array
        setData(fetchedData);

        const imageId = fetchedData.acf.header.imagem_de_fundo;
        if (imageId) {
          const imageUrl = await fetchImageUrl(imageId); // Chamada à função que busca a URL da imagem
          setBackgroundImageUrl(imageUrl); // Definir a URL da imagem no estado
        }

        setLoading(false); // Desativar o estado de carregamento
      })
      .catch((error) => {
        setError(error);
        setLoading(false); // Desativar o estado de carregamento em caso de erro
      });
  }, []);

  const handleGoTo = () => {
    navigate("/Empresa");
  };

  const goToPage = (page: string, sectionID: string) => {
    if (isOpen) {
      setIsOpen(false);
      setTimeout(() => {
        navigate(`${page}?section=${sectionID}`);
      }, 300);
    }
  };

  const handleScroll = () => {
    const secao = document.getElementById("servicos");
    if (secao) {
      secao.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Exibir loader enquanto os dados estão sendo carregados
  if (loading) {
    return <Loader />;
  }

  // Exibir mensagem de erro se algo der errado
  if (error) {
    return (
      <div className="text-white flex justify-center items-center h-full">
        <p>Erro ao carregar o conteúdo: {error.message}</p>
      </div>
    );
  }

  // Garantir que o objeto 'header' exista
  const header = data?.acf?.header || {};
  const destaque = header?.destaque || "Inovação, qualidade e compromisso";
  const titulo = header?.titulo || "Pavimentação Asfáltica com Excelência e Durabilidade.";
  const descricao =
    header?.descricao ||
    "Trazemos inovação e profissionalismo à pavimentação asfáltica. Com serviços personalizados e atenção aos detalhes, garantimos pavimentos que combinam resistência e estética para todas as suas necessidades.";

  return (
    <div
      id="hero"
      className="bg-slate-500 h-[600px] bg-cover"
      style={{
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : "url('/img/background-img.png')",
      }}
    >
      <div className="container">
        <div className="grid lg:grid-cols-12 md:grid-cols-6 gap-8">
          <div className="mt-[72px] lg:col-span-6 md:col-span-5 flex flex-col items-start gap-6 lg:gap-6">
            <Badge className="bg-brand-primary-900 gap-2 font-medium hover:bg-brand-primary-900">
              <Construction strokeWidth={1} className=" w-4 h-4" />
              {destaque}
            </Badge>
            <h2 className="font-bold text-3xl md:text-4xl md:leading-10 text-white">
              {titulo}
            </h2>
            <p className="text-md md:text-lg font-normal text-white max-w-md md:max-w-full">
              {descricao}
            </p>
            <div className="flex gap-3 mt-2">
              <Button
                size="lg"
                onClick={() => goToPage("/", "contato")}
                className="bg-brand-secondary-500 gap-3 rounded-sm font-medium hover:bg-brand-secondary-600"
              >
                Entrar em contato
                <ArrowRight strokeWidth={2} className=" w-5 h-5"></ArrowRight>
              </Button>
              <Button
                onClick={handleGoTo}
                size="lg"
                variant="outline"
                className="bg-inherit text-white rounded-sm font-medium hidden md:flex"
              >
                Saiba mais
              </Button>
            </div>
          </div>
        </div>

        <span
          onClick={handleScroll}
          className="inline-flex flex-col items-center gap-2 w-full justify-center md:mt-24 lg:mt-28 xl:mt-32 cursor-pointer"
        >
          <CircleChevronDownIcon className="items-center text-white hidden md:flex animate-bounce" />
          <p className="text-sm text-white hidden md:flex ">
            Role para ver mais
          </p>
        </span>
      </div>
    </div>
  );
};

export default Hero;
