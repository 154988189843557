import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import CardServicos from "./cardServicos";

// Interface para cada serviço individual
interface Servico {
  titulo: string;
  descricao: string;
  imagem: number;
}

// Interface para o tipo de dados da API
interface ApiResponse {
  acf: {
    nossos_servicos: {
      descricao: string;
      servico_1: Servico;
      servico_2: Servico;
      servico_3: Servico;
    };
  };
}

// Interface para a resposta da imagem
interface ImageResponse {
  source_url: string;
}

const fetchImageUrl = async (imageId: number): Promise<string | null> => {
  try {
    const response = await fetch(`https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/media/${imageId}`);
    if (!response.ok) {
      throw new Error("Erro ao buscar imagem");
    }
    const data: ImageResponse = await response.json();
    return data.source_url;
  } catch (error) {
    console.error("Erro ao buscar a imagem:", error);
    return null;
  }
};

const Servicos: React.FC = () => {
  const location = useLocation();
  const servicosSectionRef = useRef<HTMLDivElement | null>(null);
  const [data, setData] = useState<ApiResponse | null>(null); // Estado para os dados da API
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState<Error | null>(null); // Estado de erro
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string | null }>({
    servico1: null,
    servico2: null,
    servico3: null,
  }); // Estado para armazenar as URLs das imagens

  // Função para rolar até a seção específica
  const scrollToSection = (sectionID: string) => {
    const sectionRef = sectionID === "servicos" ? servicosSectionRef : null;
    if (sectionRef?.current) {
      setTimeout(() => {
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    // Chamada da API para obter os dados
    fetch("https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/home?slug=home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar dados");
        }
        return response.json();
      })
      .then((data: ApiResponse[]) => {
        const fetchedData = data[0]; // Assumindo que os dados retornam como um array
        setData(fetchedData);
        setLoading(false); // Desativar o estado de carregamento

        // Buscar as URLs das imagens com base nos IDs de mídia
        if (fetchedData.acf.nossos_servicos) {
          const { servico_1, servico_2, servico_3 } = fetchedData.acf.nossos_servicos;

          // Fazer as chamadas para buscar as URLs das imagens
          Promise.all([
            fetchImageUrl(servico_1.imagem),
            fetchImageUrl(servico_2.imagem),
            fetchImageUrl(servico_3.imagem),
          ]).then(([img1, img2, img3]) => {
            setImageUrls({
              servico1: img1,
              servico2: img2,
              servico3: img3,
            });
          });
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false); // Desativar o estado de carregamento em caso de erro
      });

    const params = new URLSearchParams(location.search);
    const section = params.get("section");
    if (section) {
      scrollToSection(section);
    }
  }, [location]);

  // Exibir loader enquanto os dados estão sendo carregados
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="text-white">Carregando...</p>
      </div>
    );
  }

  // Exibir mensagem de erro se algo der errado
  if (error) {
    return (
      <div className="text-white flex justify-center items-center h-full">
        <p>Erro ao carregar o conteúdo: {error.message}</p>
      </div>
    );
  }

  // Garantir que o objeto 'nossos_servicos' exista
  const nossosServicos = data?.acf?.nossos_servicos;

  if (!nossosServicos) {
    return <p className="text-white">Nenhum serviço disponível</p>;
  }

  const { descricao, servico_1, servico_2, servico_3 } = nossosServicos;

  return (
    <div
      ref={servicosSectionRef}
      id="servicos"
      className="mx-auto flex flex-col lg:flex-row md:flex-col border-t border-slate-200"
    >
      <div className="bg-brand-primary-950 pb-10 w-full max-w-full md:max-w-full lg:max-w-lg 2xl:max-w-2xl flex">
        <div className="pl-8 xl:ml-8 2xl:ml-64 md:ml-4">
          <div className="flex flex-col pr-10 pt-14 gap-3">
            <div>
              <p className="text-brand-secondary-500 uppercase text-sm font-semibold">
                +30 anos de experiência
              </p>
              <h2 className="text-4xl font-semibold text-white">
                Nossos serviços
              </h2>
            </div>
            <h2 className="text-white font-normal md:max-w-sm">
              {descricao}
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full">
        <CardServicos
          title={servico_1.titulo}
          description={servico_1.descricao}
          img={imageUrls.servico1 || ""} // URL da imagem obtida via API
        />
        <CardServicos
          title={servico_2.titulo}
          description={servico_2.descricao}
          img={imageUrls.servico2 || ""} // URL da imagem obtida via API
        />
        <CardServicos
          title={servico_3.titulo}
          description={servico_3.descricao}
          img={imageUrls.servico3 || ""} // URL da imagem obtida via API
        />
      </div>
    </div>
  );
};

export default Servicos;
