import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./sections/header/header";
import Home from "./pages/homePage";
import Empresa from "./pages/empresaPage";
import Footer from "./sections/footer/footer";
import Blog from "./pages/blogPage";
import PrivacyPolicy from "./pages/privacyPolicy";
import BlogPost from "./pages/blog/[slug]";
import ComplaintChannel from "./pages/complaintChannel";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="empresa" element={<Empresa />} />
        <Route path="blog" element={<Blog />} />
        <Route path="politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="blog/:slug" element={<BlogPost />} />
        <Route path="canal-de-denuncia" element={<ComplaintChannel />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
