import { useEffect, useState } from "react";
import AboutGalery from "@/sections/about/aboutGalery";
import Team from "./team";
import Principles from "./principles";

// Interface para o tipo de dados retornados pela API
interface Empresa {
  acf: {
    titulo: string;
    descricao: string; // HTML como string
  };
}

const About = () => {
  const [empresaData, setEmpresaData] = useState<Empresa | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.asphaltpavimentacao.com.br/wp-json/wp/v2/empresa?slug=empresa"
        );
        const data: Empresa[] = await response.json();
        setEmpresaData(data[0]); // Armazena o primeiro item
      } catch (error) {
        console.error("Erro ao buscar os dados da empresa:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="container grid grid-cols-2 md:grid-cols-12 lg:grid-cols-12 gap-12 py-8 md:py-12">
        <div className="col-span-2 md:col-span-6 lg:col-span-6">
          {empresaData ? (
            <p className="text-base md:text-base leading-relaxed text-slate-950">
              <strong>{empresaData.acf.titulo}</strong>
              <br />
              <span
                dangerouslySetInnerHTML={{ __html: empresaData.acf.descricao }}
              />
            </p>
          ) : (
            <p>Carregando dados...</p>
          )}
        </div>
        <AboutGalery />
      </div>
      <Principles />
      <div className="border-b border-slate-200">
        <Team />
      </div>
    </>
  );
};

export default About;
